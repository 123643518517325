#projects {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
  @media only screen and (min-width: $cut) {
    width: 80%;
  }
}

.user-projects {
  margin: 20px auto;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  h5 {
    margin: 0;
  }
  li {
    color: $main;
    font-size: 1.1rem;
  }
  p {
    margin-right: 5px;
  }
}

.contents {
  @media only screen and (min-width: $cut) {
    float: left;
    margin-left: 0;
    width: 48%;
    margin-top: -5%;
  }
}

.contents-right {
  @media only screen and (min-width: $cut) {
    float: right;
    margin-top: -5%;
    width: 48%;
  }
}
.images-right {
  @media only screen and (min-width: $cut) {
    float: right;
    width: 48%;
  }
  img {
    @media only screen and (min-width: $cut) {
      float: right;
    }
  }
}

.images-left {
  @media only screen and (min-width: $cut) {
    float: left;
    width: 48%;
  }
  img {
    @media only screen and (min-width: $cut) {
      float: left;
    }
  }
}

.project-link {
  display: inline-block;
  margin: 10px 0px;
  padding: 5px;
  color: $main;
  background-color: transparent;
  border: 1px solid $main;
  border-radius: 10px;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease-out,
              background-color 0.3s ease-out,
              border-color 0.3s ease-out;
  &:hover {
    background-color: $purple;
    border-color: $purple;
    color: #fff;
    transition: color 0.3s ease-in,
                background-color 0.3s ease-in,
                border-color 0.3s ease-in;
  }
  &:active {
    background-color: $purple;
    border-color: $purple;
    color: #fff;
    transition: color 0.3s ease-in,
                background-color 0.3s ease-in,
                border-color 0.3s ease-in;
  }
}
