#particles-js {
  background: $main;
  display: flex;
  vertical-align: bottom;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  .particles-js-canvas-el {
    width:100% !important;
    height:100vh !important;
  }
}

.header {
  z-index: 1;
  text-align: center;
  color:#FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  padding:0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  .site-logo {
    margin: 0; padding:0px;
    width:300px;
    height:300px;
    @media only screen and (min-width:750px){
      width:450px;
      height:450px;
    }
  }
  .site-title {
    font-size: 50px;
    display: block;
    line-height: 1;
    color: #FFF;
    @media only screen and (min-width:$cut){
      margin: 0;
      font-size: 100px;
    }
  }
  .site-description {
    font-size: 20px;
    display: block;
    line-height: 1;
    color: #FFF;
    margin-top: 10px;
    @media only screen and (min-width:$cut){
      font-size: 40px;
    }
  }
}

.header-links {
  margin: 10px;
  .link {
    color: #FFF;
    text-decoration: none;
    font-size: 15px;
    margin: 10px;
    @media only screen and (min-width:$cut){
      font-size: 20px;
    }
  }
}

.header-icons {
  display: flex;
  justify-content: center;
  text-align: center;
  .icon {
    text-align: center;
    color: #FFF;
    width: 20px;
    height: 20px;
    font-size: 20px;
    padding: 10px;
    margin: 5px;
    border-radius: 50%;
    border: 2px solid #FFF;
    transition: all .7s;
    &:hover {
      color: $main;
      background: #FFF;
    }
    &:active {
      color: $main;
      background: #FFF;
    }
    @media only screen and (min-width:$cut) {
      width: 35px;
      height: 35px;
      font-size: 35px;
    }
  }
}

.down {
  color: #FFF;
  position: absolute;
  bottom: 25px;
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 30px;
  cursor: pointer;
  .icon {
      position: absolute;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      fill: #fff;
      -webkit-animation: pulse 1.3s infinite;
      animation: pulse 1.3s infinite;
      &:hover {
        color: $sec;
      }
      &:active {
        color: $sec;
      }
  }
}
