@import "lib/normalize";
@import "lib/fontawesome";
@import "lib/devicon";

@import 'base';
@import 'vars';
@import 'animation';
@import 'header';
@import 'about';
@import 'projects';
@import 'footer';

h3 a {
  color: #333;
  text-decoration: none;
}

.user-projects a {
  color: #333;
}
